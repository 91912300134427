import { useQuery, useMutation } from 'react-query';
import AuthService from 'services/AuthService';
import ProfileServices from 'services/ProfileServices';

const fetchProfile = () => {
  return ProfileServices.fetchProfile();
};

const login = (data) => {
  return AuthService.login(data);
};
const forgetPassword = (data) => {
  return AuthService.forgetPassword(data);
};
const resetPassword = (token, data) => {
  return AuthService.resetPassword(token, data);
};

export const useGetProfile = (onSuccess) => {
  return useQuery('authentication-user', () => fetchProfile(), {
    onSuccess,
    refetchOnWindowFocus: false,
  });
};

export const useUserLogin = (onSuccess, onError) => {
  return useMutation(login, { onSuccess, onError });
};
export const useForgetPassword = (onSuccess, onError) => {
  return useMutation(forgetPassword, { onSuccess, onError });
};
export const useResetPassword = (token, onSuccess, onError) => {
  return useMutation((data) => resetPassword(token, data), {
    onSuccess,
    onError,
  });
};

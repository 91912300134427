import {
  DashboardIcon,
  ShipmentsIcon,
  SettingsIcon,
  ReporterIcon,
  ClientsIcon,
  ServiceIcon,
  CallsIcon,
  ContactIcon,
} from 'assets/svg';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.dashboard',
    icon: DashboardIcon,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'shipments',
    path: `${APP_PREFIX_PATH}/shipments`,
    title: 'sidenav.shipments',
    icon: ShipmentsIcon,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'reporter',
    path: `${APP_PREFIX_PATH}/reporter`,
    title: 'sidenav.reporter',
    icon: ReporterIcon,
    breadcrumb: false,
    submenu: [],
    permissionsSlug: 'reporter_show',
  },
  {
    key: 'merchants',
    path: `${APP_PREFIX_PATH}/merchants`,
    title: 'sidenav.merchants',
    icon: ClientsIcon,
    breadcrumb: false,
    submenu: [],
    permissionsSlug: 'merchants_show',
  },
  {
    key: 'service-provider',
    path: `${APP_PREFIX_PATH}/service-provider`,
    title: 'sidenav.service-provider',
    icon: ServiceIcon,
    breadcrumb: false,
    submenu: [],
    permissionsSlug: 'service_provider_show',
  },
  {
    key: 'calls',
    path: `${APP_PREFIX_PATH}/calls`,
    title: 'sidenav.calls',
    icon: CallsIcon,
    breadcrumb: false,
    submenu: [],
    permissionsSlug: 'calls_show',
  },
  {
    key: 'contact-us',
    path: `${APP_PREFIX_PATH}/contact-us`,
    title: 'sidenav.contact-us',
    icon: ContactIcon,
    breadcrumb: false,
    submenu: [],
    permissionsSlug: 'contact_us_show',
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'sidenav.settings',
    icon: SettingsIcon,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;

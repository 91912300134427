import React from 'react';
import { Button } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { onLocaleChange, onDirectionChange } from 'redux/actions/Theme';
import { DIR_KEY } from 'constants/ThemeConstant';
import { ArabicIcon, EnglishIcon } from 'assets/svg';

const ChangeLang = () => {
  const locale = useSelector((s) => s.theme.locale);
  const dispatch = useDispatch();
  const handlechangeLang = () => {
    if (locale === 'en-US' || locale === 'en') {
      i18next.changeLanguage('ar');
      dispatch(onLocaleChange('ar'));
      localStorage.setItem(DIR_KEY, 'rtl');
      dispatch(onDirectionChange('rtl'));
    } else {
      i18next.changeLanguage('en-US');
      dispatch(onLocaleChange('en-US'));
      localStorage.setItem(DIR_KEY, 'ltr');
      dispatch(onDirectionChange('ltr'));
    }
  };
  return (
    <Button
      type={'link'}
      className={'langauge-switcher'}
      onClick={handlechangeLang}
    >
      {locale === 'ar' ? <EnglishIcon /> : <ArabicIcon />}{' '}
      {locale === 'ar' ? 'EN' : 'AR'}
    </Button>
  );
};

export default ChangeLang;

import fetch from 'auth/AuthInterceptor';

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: '/authentication/login',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};
AuthService.forgetPassword = function (data) {
  return fetch({
    url: '/authentication/forget-password',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};
AuthService.resetPassword = function (token, data) {
  return fetch({
    url: `/authentication/reset-password/${token}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};
AuthService.user = function () {
  return fetch({
    url: '/authentication/user',
    method: 'get',
    headers: {
      'public-request': 'true',
    },
  });
};

export default AuthService;

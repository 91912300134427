import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const ErrorsViews = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route path={`/404`} component={lazy(() => import(`../errors/404`))} />
        <Route path={`/500`} component={lazy(() => import(`../errors/500`))} />
      </Switch>
    </Suspense>
  );
};

export default ErrorsViews;

import fetch from 'auth/AuthInterceptor';
const ProfileServices = {};

ProfileServices.fetchProfile = function () {
  return fetch({
    url: '/authentication/user',
    method: 'get',
    headers: {
      'public-request': 'true',
    },
  });
};
ProfileServices.updateProfileInfo = function (data) {
  return fetch({
    url: `/users/profile/info`,
    method: 'patch',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};
ProfileServices.updateProfileAddress = function (data) {
  return fetch({
    url: `/users/profile/address`,
    method: 'patch',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};
ProfileServices.updatePassword = function (data) {
  return fetch({
    url: `/users/profile/security`,
    method: 'patch',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

export default ProfileServices;

import {
  ACCESS_TOKRN,
  REFRESH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  SET_USER_DATA,
  USER_DATA,
  SIGNOUT,
} from '../constants/Auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  accessToken: localStorage?.getItem(ACCESS_TOKRN),
  refreshToken: localStorage?.getItem(REFRESH_TOKEN),
  userData: JSON.parse(localStorage?.getItem(USER_DATA)),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNOUT: {
      localStorage.removeItem(ACCESS_TOKRN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(USER_DATA);
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        userData: null,
        redirect: '/',
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default auth;

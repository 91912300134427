import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import useUserRole from 'hooks/UserRoles';

export const AppViews = () => {
  const admin = useUserRole('Merchant');

  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/shipments`}
          component={lazy(() => import(`./shipments`))}
        />
        {!admin ? (
        <Route
          path={`${APP_PREFIX_PATH}/reporter`}
          component={lazy(() => import(`./reporter`))}
        />
        ) : null}
        {!admin ? (
          <Route
            path={`${APP_PREFIX_PATH}/merchants`}
            component={lazy(() => import(`./merchants`))}
          />
        ) : null}
        {!admin ? (
          <Route
            path={`${APP_PREFIX_PATH}/service-provider`}
            component={lazy(() => import(`./serviceProvider`))}
          />
        ) : null}
        <Route
          path={`${APP_PREFIX_PATH}/calls`}
          component={lazy(() => import(`./calls`))}
        />
        {!admin ? (
          <Route
            path={`${APP_PREFIX_PATH}/contact-us`}
            component={lazy(() => import(`./contacts`))}
          />
        ) : null}
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);

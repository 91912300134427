import {
  SAVE_MERCHANT_STEP,
  SAVE_ADDRESS_STEP,
  CHANGE_STEP,
  RESET_DATA,
  INTIAL_DATA,
  SAVE_ZONES_STEP,
} from '../constants/Merchant';

const initState = {
  step: 0,
  data: {
    id: '',
    name: '',
    email: '',
    mobile: '',
    role: 'Merchant',
    acquisitionAgent: '',
    address: '',
    categoryId: '',
    merchantTypeId: '',
    areaId: '',
    cityId: '',
    feesZone1: '',
    feesZone2: '',
    feesZone3: '',
    feesZone4: '',
    feesZone5: '',
    feesZone6: '',
    feesZone7: '',
    feesZone8: '',
  },
};

const merchants = (state = initState, action) => {
  switch (action.type) {
    case SAVE_MERCHANT_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 1,
      };
    case SAVE_ADDRESS_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 2,
      };
    case SAVE_ZONES_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 2,
      };
    case INTIAL_DATA:
      return {
        ...state,
        data: {
          name: action.payload.name ? action.payload.name : '',
          email: action.payload.email ? action.payload.email : '',
          mobile: action.payload?.merchant?.mobile
            ? action.payload?.merchant?.mobile
            : '',
          role: 'Merchant',
          acquisitionAgent: action.payload?.merchant?.acquisitionAgent
            ? action.payload?.merchant?.acquisitionAgent
            : '',
          address: action.payload?.merchant?.address
            ? action.payload?.merchant?.address
            : '',
          categoryId: action.payload?.merchant?.category?.id
            ? action.payload?.merchant?.category.id
            : '',
          id: action.payload?.merchant?.id ? action.payload?.merchant?.id : '',
          merchantTypeId: action.payload?.merchant?.type?.id
            ? action.payload?.merchant?.type.id
            : '',
          areaId: action.payload?.merchant?.area?.id
            ? action.payload?.merchant?.area.id
            : '',
          cityId: action.payload?.merchant?.city?.id
            ? action.payload?.merchant?.city.id
            : '',
          feesZone1: action.payload?.merchant?.feesZone1
            ? action.payload?.merchant?.feesZone1
            : '',
          feesZone2: action.payload?.merchant?.feesZone2
            ? action.payload?.merchant?.feesZone2
            : '',
          feesZone3: action.payload?.merchant?.feesZone3
            ? action.payload?.merchant?.feesZone3
            : '',
          feesZone4: action.payload?.merchant?.feesZone4
            ? action.payload?.merchant?.feesZone4
            : '',
          feesZone5: action.payload?.merchant?.feesZone5
            ? action.payload?.merchant?.feesZone5
            : '',
          feesZone6: action.payload?.merchant?.feesZone6
            ? action.payload?.merchant?.feesZone6
            : '',
          feesZone7: action.payload?.merchant?.feesZone7
            ? action.payload?.merchant?.feesZone7
            : '',
          feesZone8: action.payload?.merchant?.feesZone8
            ? action.payload?.merchant?.feesZone8
            : '',
        },
      };
    case RESET_DATA:
      return {
        ...state,
        data: {
          name: '',
          email: '',
          phoneNumber: '',
          role: 'Merchant',
          merchant: {
            address: '',
            feesZone1: '',
            feesZone2: '',
            feesZone3: '',
            feesZone4: '',
            feesZone5: '',
            feesZone6: '',
            feesZone7: '',
            feesZone8: '',
            category: {
              id: '',
            },
            type: {
              id: '',
            },
            area: {
              id: '',
            },
            city: {
              id: 1,
            },
          },
        },
        step: 0,
      };
    case CHANGE_STEP:
      return {
        ...state,
        step: action.payload,
      };

    default:
      return state;
  }
};

export default merchants;

export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const ACCESS_TOKRN = 'ACCESS_TOKRN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const USER_DATA = 'USER_DATA';

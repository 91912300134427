import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { ACCESS_TOKRN } from 'redux/constants/Auth';
import { notification } from 'antd';
import { toast } from 'react-toastify';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKRN);

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${token}`;
    }

    if (!token && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Remove token and redirect
    if (error.response.status === 401) {
      toast.error('not Authorized');
      localStorage.removeItem(ACCESS_TOKRN);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      toast.error('Not Found');
    }

    if (error.response.status === 500) {
      toast.error('Internal Server Error');
    }

    if (error.response.status === 508) {
      toast.error('Time Out');
    }

    return Promise.reject(error);
  }
);

export default service;

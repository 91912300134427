import {
  SAVE_SHIPMENTS_STEP,
  SAVE_SHIPPER_STEP,
  SAVE_CONSIGNEE_STEP,
  RESET_DATA,
  CHANGE_STEP,
  INTIAL_DATA,
} from '../constants/Shipments';

const initState = {
  step: 0,
  data: {
    shipmentType: '',
    shipmentStatus: '',
    refNumber: '',
    shippingFees: '',
    CODamount: '',
    category: '',
    itemsCount: '',
    packageWeight: '',
    rejectionReason: '',
    subRejectionReason: '',
    description: '',

    SPTN: '',
    serviceProvider: '',
    // firstMileHub: '',
    packageType: '',
    paymentMethod: '',
    refundAmount: '',

    shipperMobile: '',
    shipperAddress: '',
    shipperCity: '',
    shipperArea: '',
    merchant: '',
    customerName: '',
    customerMobile: '',
    customerEmail: '',
    consigneeAddress: '',
    consigneeCity: '',
    consigneeArea: '',
  },
};

const singleٍShipment = (state = initState, action) => {
  switch (action.type) {
    case SAVE_SHIPMENTS_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 1,
      };
    case SAVE_SHIPPER_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 2,
      };
    case SAVE_CONSIGNEE_STEP:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        step: 2,
      };
    case INTIAL_DATA:
      return {
        ...state,
        data: {
          shipmentType: action.payload.shipmentType
            ? action.payload.shipmentType.id
            : '',
          shipmentStatus: action.payload.shipmentStatus
            ? action.payload.shipmentStatus.id
            : '',
          refNumber: action.payload?.refNumber ? action.payload?.refNumber : '',
          shippingFees: action.payload?.shippingFees
            ? action.payload?.shippingFees
            : '',
          CODamount: action.payload?.CODamount ? action.payload?.CODamount : '',
          category: action.payload?.category ? action.payload?.category.id : '',
          itemsCount: action.payload?.itemsCount
            ? action.payload?.itemsCount
            : '',
          packageWeight: action.payload?.packageWeight
            ? action.payload?.packageWeight
            : '',
          rejectionReason: action.payload?.rejectionReason
            ? action.payload?.rejectionReason.id
            : '',
          subRejectionReason: action.payload?.subRejectionReason
            ? action.payload?.subRejectionReason.id
            : '',
          description: action.payload?.description
            ? action.payload?.description
            : '',
            

            SPTN: action.payload?.SPTN
            ? action.payload?.SPTN
            : '',
            serviceProvider: action.payload?.serviceProvider
            ? action.payload?.serviceProvider.id
            : '',
            // firstMileHub: action.payload?.firstMileHub
            // ? action.payload?.firstMileHub
            // : '',
            packageType: action.payload?.packageType
            ? action.payload?.packageType.id
            : '',
            paymentMethod: action.payload?.paymentMethod
            ? action.payload?.paymentMethod.id
            : '',
            refundAmount: action.payload?.refundAmount
            ? action.payload?.refundAmount
            : '',


          shipperMobile: action.payload?.shipperMobile
            ? action.payload?.shipperMobile
            : '',
          shipperAddress: action.payload?.shipperAddress
            ? action.payload?.shipperAddress
            : '',
          shipperCity: action.payload?.shipperCity
            ? action.payload?.shipperCity.id
            : '',
          shipperArea: action.payload?.shipperArea
            ? action.payload?.shipperArea.id
            : '',
          merchant: action.payload?.merchant ? action.payload?.merchant.id : '',
          customerName: action.payload?.customerName
            ? action.payload?.customerName
            : '',
          customerMobile: action.payload?.customerMobile
            ? action.payload?.customerMobile
            : '',
          customerEmail: action.payload?.customerEmail
            ? action.payload?.customerEmail
            : '',
          consigneeAddress: action.payload?.consigneeAddress
            ? action.payload?.consigneeAddress
            : '',
          consigneeCity: action.payload?.consigneeCity
            ? action.payload?.consigneeCity.id
            : '',
          consigneeArea: action.payload?.consigneeArea
            ? action.payload?.consigneeArea.id
            : '',
        },
      };
    case RESET_DATA:
      return {
        ...state,
        data: {
          shipmentType: '',
          shipmentStatus: '',
          refNumber: '',
          shippingFees: '',
          CODamount: '',
          category: '',
          itemsCount: '',
          packageWeight: '',
          rejectionReason: '',
          subRejectionReason: '',
          description: '',

          SPTN: '',
          serviceProvider: '',
          // firstMileHub: '',
          packageType: '',
          paymentMethod: '',
          refundAmount: '',

          shipperMobile: '',
          shipperAddress: '',
          shipperCity: '',
          shipperArea: '',
          merchant: null,
          customerName: '',
          customerMobile: '',
          customerEmail: '',
          consigneeAddress: '',
          consigneeCity: '',
          consigneeArea: '',
        },
        step: 0,
      };
    case CHANGE_STEP:
      return {
        ...state,
        step: action.payload,
      };

    default:
      return state;
  }
};

export default singleٍShipment;

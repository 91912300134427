import { combineReducers } from 'redux';
import Auth from './Auth';
import merchants from './Merchants';
import singleٍShipment from './Shipments';
import Theme from './Theme';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  singleٍShipment: singleٍShipment,
  merchants: merchants,
});

export default reducers;

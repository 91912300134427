import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import ErrorsViews from './errors';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, accessToken, location, direction } = props;
  const currentAppLocale = AppLocale[locale.split('-')[0]];
  useBodyClass(`dir-${direction}`, direction);

  return (
    <>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>
            <Route exact path='/'>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>
            <RouteInterceptor
              path={APP_PREFIX_PATH}
              isAuthenticated={accessToken}
            >
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>
            <Route path='/404'>
              <ErrorsViews />
            </Route>
            <Route path='*'>
              <Redirect to={`/404`} />
            </Route>
          </Switch>
        </ConfigProvider>
      </IntlProvider>
      <ToastContainer autoClose={false} position='top-right' />
    </>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { accessToken } = auth;
  return { locale, accessToken, direction };
};

export default withRouter(connect(mapStateToProps)(Views));

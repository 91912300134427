/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'components/util-components/Icon';

import { signOut } from 'redux/actions/Auth';
import Utils from 'utils';
import Profile from './Profile';
import { ClientsIcon, LogoutIcon } from 'assets/svg';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const menuItem = [
  {
    title: 'edit_profile',
    icon: ClientsIcon,
    path: '/app/settings',
  },
];

export const NavProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userData } = useSelector((s) => s.auth);
  const history = useHistory();
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <Profile />
      </div>
      <div className='nav-profile-body'>
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a
                  href='#'
                  onClick={() =>
                    history.push({
                      pathname: '/app/settings',
                      state: { key: 3 },
                    })
                  }
                >
                  <span className='sid-icon'>
                    <Icon type={el.icon} />
                  </span>

                  <span>{t(`${el.title}`)}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => dispatch(signOut())}
          >
            <span>
              <span className='sid-icon'>
                <LogoutIcon />
              </span>
              <span>
                <Trans>sign_out</Trans>
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item key='profile' className='my-profile'>
          <Avatar size='small' className='profile_avater'>
            {Utils.getNameInitial(userData?.name)}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;

import React from 'react';
import { Avatar } from 'antd';
import Utils from 'utils';
import { useSelector } from 'react-redux';
const Profile = () => {
  const { userData } = useSelector((s) => s.auth);
  return (
    <div className='profile-container'>
      <Avatar size='small' className='profile_avater' src=''>
        {Utils.getNameInitial(userData?.name)}
      </Avatar>
      <div className='profile-info'>
        <div className='name'>{userData?.name}</div>
        <div className='title'>{userData?.role}</div>
      </div>
    </div>
  );
};

export default Profile;
